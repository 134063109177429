/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.account-container {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.account-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
}

.account-nav {
  float: right;
  padding-top: 11px;
}

.account-user {
  float: left;
}

.account-user h1 {
  line-height: 60px;
}

.account-user-avatar {
  margin-right: 20px;
}

.account-user-avatar > img {
  border-radius: 60px;
}

.account-user-avatar:empty {
  display: none;
}

.account-body {
  padding: 40px 0;
}

.account-profile .boxed-group-inner:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.account-projects-list {
  margin-left: -20px;
  margin-right: -20px;
}

.account-projects-list > li {
  padding: 15px 20px;
}

.account-projects-list > li + li {
  border-top: 1px solid #e6e6e6;
}

.account-project-side {
  float: right;
  margin-left: 10px;
  text-align: right;
}

.account-project-analysis {
  line-height: 24px;
  color: #666;
  font-size: 12px;
}

.account-project-card {
  position: relative;
  display: block;
}

.account-project-name {
  display: inline-block;
  vertical-align: top;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account-project-name > a {
  border-bottom-color: #d0d0d0;
  color: #333;
}

.account-project-name > a:hover {
  border-bottom-color: #cae3f2;
  color: #4b9fd5;
}

.account-project-quality-gate {
  display: inline-block;
  vertical-align: top;
  line-height: 24px;
  margin-left: 8px;
}

.account-project-description {
  margin-top: 6px;
  line-height: 1.5;
}

.account-project-links {
  margin-top: 6px;
}

.account-project-key {
  margin-top: 6px;
  color: #666;
  font-size: 12px;
}

.my-activity-issues {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.my-activity-issues:after {
  position: absolute;
  z-index: 5;
  top: -15px;
  left: 50%;
  width: 1px;
  height: 100px;
  background-color: #e6e6e6;
  transform: rotate(30deg);
  content: '';
}

.my-activity-issues > a {
  display: block;
  padding: 15px 20px;
  border: none;
  border-radius: 2px;
  color: #333;
}

.my-activity-issues > a:hover {
  background-color: #f3f3f3;
}

.my-activity-recent-issues {
  margin-right: 50px;
  text-align: right;
}

.my-activity-recent-issues .my-activity-issues-note {
  text-align: left;
}

.my-activity-all-issues {
  margin-left: 50px;
}

.my-activity-issues-number {
  display: inline-block;
  vertical-align: middle;
  line-height: 36px;
  font-size: 36px;
  font-weight: 300;
}

.my-activity-issues-note {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  padding-top: 2px;
  line-height: 16px;
  font-size: 12px;
}

.my-activity-projects {
  width: 360px;
  margin: 0 auto;
  padding: 40px 0;
}

.my-activity-projects-header {
  line-height: 24px;
  margin-bottom: 15px;
  padding: 0 10px;
}

.my-activity-projects > ul > li + li {
  border-top: 1px solid #e6e6e6;
}

.my-activity-projects > ul > li > a {
  display: block;
  padding: 15px 10px;
  border: none;
}

.my-activity-projects > ul > li > a:hover {
  background-color: #f3f3f3;
}

.my-activity-projects .level {
  width: 60px;
}

.my-activity-projects .more {
  margin-top: 30px;
  text-align: center;
}

.notifications-table {
  margin-top: -16px;
}

.notifications-add-project-no-search-results {
  padding: 8px;
}

.notifications-add-project-search-results li {
  padding: 8px;
  cursor: pointer;
}

.notifications-add-project-search-results li:hover,
.notifications-add-project-search-results li.active {
  background-color: #f3f3f3;
}
